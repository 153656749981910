import React from "react";
import { motion } from "framer-motion";

const Button = ({ btnText }) => {
	return (
		<div className="relative z-10 group">
			<motion.button
				transition={{ type: "spring", stiffness: 100 }}
				whileHover={{ position: "relative", top: 8, left: 8 }}
				className="py-5 px-6 bg-troo-primary text-troo-tertiary rounded-lg shadow-xl"
			>
				{btnText}
			</motion.button>
			<div className="block absolute top-2 transition-all duration-250 ease-linear left-2 w-full h-full rounded-lg bg-troo-light -z-10" />
		</div>
	);
};

export default Button;
