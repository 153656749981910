import React from "react";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import Facebook from "../../shared/assets/images/icons/facebook.svg";
import Twitter from "../../shared/assets/images/icons/x.svg";
import LinkedIn from "../../shared/assets/images/icons/linkedin.svg";
import Instagram from "../../shared/assets/images/icons/instagram.svg";
import Mail from "../../shared/assets/images/icons/mail.svg";
import Phone from "../../shared/assets/images/icons/phone-call.svg";
import LocationPin from "../../shared/assets/images/icons/marker.svg";

const ContactUs = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Get In Touch With Us" />}>
			<div className="main-container-big w-full bg-troo-tertiary py-[80px]">
				<div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-8">
					<div className="w-full p-8 rounded-lg border border-troo-primary border-opacity-10 flex flex-col items-center justify-center text-troo-light text-center gap-3">
						<div className="p-4 rounded-full bg-troo-primary bg-opacity-10">
							<img src={Phone} alt="phone" />
						</div>
						<p className="text-base lg:text-lg">Need Help? Call Us on</p>
						<p className="text-xl lg:text-2xl font-extrabold">+44 123 456 7890</p>
					</div>
					<div className="w-full p-8 rounded-lg border border-troo-primary border-opacity-10 flex flex-col items-center justify-center text-troo-light text-center gap-3">
						<div className="p-4 rounded-full bg-troo-primary bg-opacity-10">
							<img src={Mail} alt="Mail" />
						</div>
						<p className="text-base lg:text-lg">Email Us</p>
						<p className="text-xl lg:text-2xl font-extrabold">info@troothems.com</p>
					</div>
					<div className="w-full p-8 rounded-lg border border-troo-primary border-opacity-10 flex flex-col items-center justify-center text-troo-light text-center gap-3">
						<div className="p-4 rounded-full bg-troo-primary bg-opacity-10">
							<img src={LocationPin} alt="LocationPin" />
						</div>
						<p className="text-base lg:text-lg">Our Office Address</p>
						<p className="text-xl lg:text-2xl font-extrabold">
							4516 School Street
							<br /> Danbury, CT, Canada
						</p>
					</div>
				</div>
			</div>
			<div className="main-container-big w-full bg-troo-tertiary py-[80px]">
				<div className="w-full bg-troo-secondary flex flex-col items-start md:grid grid-cols-2 gap-8 p-12 rounded-xl">
					<div className="flex flex-col items-start gap-5 text-troo-light">
						<p>
							Have Any <br /> Questions?
						</p>
						<h3>
							Need Any Help? Don’t <br /> Hesitate To Contact With Us
						</h3>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alterapassages of Lorem Ipsum
							available,tion in some form, by injected humour Lorem Ipsum
							available.
						</p>
						<div className="flex items-center gap-2 w-full">
							<p>Social Media</p>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Facebook} alt="facebook" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Twitter} alt="twitter" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={LinkedIn} alt="linked in" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Instagram} alt="instagram" className="w-4 h-4" />
							</div>
						</div>
					</div>
					<div className="rounded-xl flex flex-col items-start sm:grid grid-cols-2 gap-6 w-full text-troo-light">
						<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
							<input
								type="text"
								placeholder="Full Name"
								className="bg-transparent w-full outline-none"
							/>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
								/>
							</svg>
						</div>
						<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
							<input
								type="text"
								placeholder="Phone No"
								className="bg-transparent w-full outline-none"
							/>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
								/>
							</svg>
						</div>
						<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
							<input
								type="text"
								placeholder="Email"
								className="bg-transparent w-full outline-none"
							/>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
								/>
							</svg>
						</div>
						<div className="pr-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
							<select className="pl-5 py-5 bg-transparent w-full outline-none">
								<option>Subject</option>
								<option>Subject 1</option>
								<option>Subject 2</option>
								<option>Subject 3</option>
							</select>
						</div>
						<textarea
							className="p-5 col-span-2 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full"
							rows={3}
							placeholder="Message"
						></textarea>

						<button className="w-full mt-4 col-span-2 py-4 px-6 bg-transparent border-2 border-troo-primary rounded-md text-troo-primary">
							Book an Appointment
						</button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ContactUs;
