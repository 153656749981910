import React from "react";

const DiscountSection = () => {
	return (
		<div className="w-full py-[100px] bg-discount text-troo-light bg-cover bg-center bg-no-repeat">
			<div className="main-container-big gap-8 items-center grid md:grid-cols-2">
				<div className="md:block hidden" />
				<div className="w-full flex flex-col items-start gap-4">
					<p className="text-lg font-semibold">
						We can present all kinds of hairstyles well and elegantly
					</p>
					<h2>
						Get a{" "}
						<span className="text-7xl text-troo-primary font-bold">50%</span> on
						Your First Reservation
					</h2>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since dummy.
					</p>
					<button className="py-4 px-6 bg-transparent border-2 border-troo-primary rounded-md text-troo-primary">
						Book an Appointment
					</button>
				</div>
			</div>
		</div>
	);
};

export default DiscountSection;
