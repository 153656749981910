import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ServiceCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }} className="p-5 rounded-md md:p-6 bg-troo-secondary flex flex-col items-start gap-4">
			<div className="w-full overflow-hidden aspect-square rounded-md h-full">
				<img
					src={data?.img}
					alt={data?.title}
					className="object-cover object-center w-full h-full"
				/>
			</div>
			<h4>{data?.title}</h4>
			<p>{data?.desc}</p>
			<Link to={ROUTES.serviceDetails} className="flex items-center gap-3">
				<p>Read More</p>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
					/>
				</svg>
			</Link>
		</motion.div>
	);
};

export default ServiceCard;
