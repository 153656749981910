import React from "react";
import Cutter from "../../shared/assets/images/cutter.svg";
import BarberImg from "../assets/images/hero-barber.png";
import Button from "../components/Button";
import { motion } from "framer-motion";

const HeroSection = () => {
	return (
		<div className="relative w-full min-h-screen py-40 bg-home-hero-banner-2 bg-no-repeat bg-cover bg-bottom flex flex-col items-center gap-8 justify-center bg-troo-tertiary bg-opacity-90 px-8">
			<div className="flex items-center gap-3">
				<img src={Cutter} alt="cutter" />
				<p className="text-troo-light">Professional Service</p>
			</div>
			<motion.h2
				initial={{ scale: 0.8 }}
				whileInView={{ scale: 1 }}
				transition={{ type: "spring", stiffness: 100 }}
				className="text-4xl md:text-6xl lg:text-7xl text-center text-troo-light">
				A good haircut makes you <br /> look like yourself…
			</motion.h2>
			<p className="text-troo-light text-center">
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam inta
				nonumy
				<br /> eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit.
			</p>
			<Button btnText="Explore Our Service" />
			<img
				src={BarberImg}
				alt="barber img"
				className="absolute bottom-0 right-0 w-48 sm:w-60 lg:w-auto"
			/>
		</div>
	);
};

export default HeroSection;
