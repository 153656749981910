import React from "react";
import { motion } from "framer-motion";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const BlogCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }} className="flex flex-col lg:flex-row items-center w-full">
			<div className="w-full lg:w-[80%] overflow-hidden aspect-video sm:aspect-auto">
				<img
					src={data?.img}
					alt="blog img"
					className="w-full h-full object-center object-cover"
				/>
			</div>
			<div className="lg:-ml-24 p-7 rounded-md w-full bg-troo-secondary flex flex-col items-start gap-4">
				<div className="py-2 px-4 bg-troo-primary text-troo-primary bg-opacity-10 rounded-md font-bold">
					{data?.date}
				</div>
				<h3 className="leading-8">{data?.title}</h3>
				<div className="w-full h-[1px] bg-troo-primary bg-opacity-10" />
				<div className="flex text-troo-primary items-center gap-2">
					<Link to={ROUTES.blogDetails}>Read More</Link>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
						/>
					</svg>
				</div>
			</div>
		</motion.div>
	);
};

export default BlogCard;
