import React from "react";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import AppointSection from "../sections/AppointSection";
import ServiceSection from "../sections/ServiceSection";

const Services = () => {
	return (
		<Layout banner={<SubPageHeroSection title="What We Provide" />}>
			<ServiceSection isListingPage />
			<AppointSection />
		</Layout>
	);
};

export default Services;
