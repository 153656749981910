import React from "react";
import Heading from "../components/Heading";
import Cutter from "../../shared/assets/images/icons/cutter.png";
import HairStyle from "../../shared/assets/images/icons/hairstyle.png";
import Avatar1 from "../../shared/assets/images/avatar-1.png";
import About1 from "../../shared/assets/images/about-1.png";
import About2 from "../../shared/assets/images/about-2.png";
import Button from "../components/Button";

const AboutSection = ({ isAboutPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-tertiary bg-opacity-95 text-troo-light flex flex-col items-start gap-16 bg-about-img bg-cover bg-no-repeat bg-center">
			<div className="main-container-big gap-8 items-center grid md:grid-cols-2">
				<div className="flex flex-col items-start gap-4">
					<Heading
						heading="ABOUT US"
						secHeading="We are the best barbers let know something about us"
						tagline="Professional Service"
					/>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don’t look even slightly
						believable. If you are going to use a passage of Lorem Ipsum.
					</p>
					<div className="grid w-full sm:grid-cols-2 gap-6 mt-5">
						<div className="flex items-start gap-3">
							<div className="bg-troo-primary p-3 rounded-lg">
								<img src={Cutter} alt="cutter" />
							</div>
							<div className="flex flex-col items-start gap-2">
								<h4>Best Equipment</h4>
								<p>There are many variations of passages of Lorem Ipsum.</p>
							</div>
						</div>
						<div className="flex items-start gap-3">
							<div className="bg-troo-primary p-3 rounded-lg">
								<img src={HairStyle} alt="HairStyle" />
							</div>
							<div className="flex flex-col items-start gap-2">
								<h4>Best Hairstyles</h4>
								<p>There are many variations of passages of Lorem Ipsum.</p>
							</div>
						</div>
					</div>
					<div className="w-full h-[1px] bg-troo-primary bg-opacity-10 my-6" />
					<div className="flex sm:flex-row flex-col items-start sm:items-center gap-10">
						<Button btnText="Learn More Us" />
						<div className="flex items-center gap-3">
							<div className="border-4 border-troo-light rounded-full overflow-hidden">
								<img src={Avatar1} alt="Avatar 1" className="rounded-full" />
							</div>
							<div className="flex flex-col items-start">
								<h5>Monika Nailson</h5>
								<p>CEO & Founer</p>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full grid grid-cols-2 gap-3">
					<div className="w-full h-full overflow-hidden rouned-lg">
						<img
							src={About1}
							alt="about 1"
							className="w-full h-full object-cover object-center"
						/>
					</div>
					<div className="flex flex-col gap-4 items-center w-full">
						<div className="w-full p-7 bg-troo-primary flex flex-col items-center gap-3">
							<h2 className="text-troo-secondary text-3xl sm:text-4xl md:text-5xl">3460+</h2>
							<p className="text-troo-secondary text-base sm:text-lg">Satisfied Customer</p>
						</div>
						<div className="w-full h-full overflow-hidden rouned-lg">
							<img
								src={About2}
								alt="about 2"
								className="w-full h-full object-cover object-center"
							/>
						</div>
					</div>
				</div>
			</div>
			{isAboutPage && (
				<div className="main-container-big w-full">
					<div className="w-full grid grid-cols-1 bg-troo-secondary sm:grid-cols-2 lg:grid-cols-4 shadow-xl p-8">
						<div className="w-full flex flex-col items-center gap-2">
							<div className="relative flex flex-col items-center justify-center">
								<p className="text-8xl font-bold text-troo-light text-opacity-5 font-secondary font-secondary">
									20
								</p>
								<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-light font-secondary">
									20
								</p>
							</div>
							<p className="text-xl text-troo-light">Professional Barbers</p>
						</div>
						<div className="w-full flex flex-col items-center gap-2">
							<div className="relative flex flex-col items-center justify-center">
								<p className="text-8xl font-bold text-troo-light text-opacity-5 font-secondary">
									25+
								</p>
								<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-secondary font-bold text-troo-light">
									25+
								</p>
							</div>
							<p className="text-xl text-troo-light">Years of Experience</p>
						</div>
						<div className="w-full flex flex-col items-center gap-2">
							<div className="relative flex flex-col items-center justify-center">
								<p className="text-8xl font-bold text-troo-light text-opacity-5 font-secondary">
									9k
								</p>
								<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-secondary font-bold text-troo-light">
									9k
								</p>
							</div>
							<p className="text-xl text-troo-light">Satisfied Customer</p>
						</div>
						<div className="w-full flex flex-col items-center gap-2">
							<div className="relative flex flex-col items-center justify-center">
								<p className="text-8xl font-bold text-troo-light text-opacity-5 font-secondary">
									218
								</p>
								<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-light font-secondary">
									218
								</p>
							</div>
							<p className="text-xl text-troo-light">Win Awards</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AboutSection;
