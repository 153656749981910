import React from "react";
import { GALARY_DATA } from "../constants";
import { motion } from "framer-motion";

const GalarySection = ({ isGalaryPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-secondary text-troo-light">
			<div className="main-container-big gap-14 flex flex-col items-center">
				<div className="w-full grid md:grid-cols-3 lg:grid-cols-4 gap-6">
					{GALARY_DATA.map((data, i) => {
						return (
							<motion.div
								initial={{ scale: 0.7 }}
								whileInView={{ scale: 1 }}
								transition={{ type: "spring", stiffness: 100 }}
								className={`relative group cursor-pointer w-full h-full aspect-square overflow-hidden ${i === 0 && "col-span-2 row-span-2"
									}`}
							>
								<div className="group-hover:flex items-center justify-center hidden absolute w-full h-full bg-troo-secondary bg-opacity-75">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
										<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
									</svg>
								</div>
								<img
									src={data?.img}
									alt="galary img"
									className="w-full h-full object-cover object-center"
								/>
							</motion.div>
						);
					})}
					{isGalaryPage &&
						GALARY_DATA.map((data, i) => {
							return (
								<motion.div
									initial={{ scale: 0.7 }}
									whileInView={{ scale: 1 }}
									transition={{ type: "spring", stiffness: 100 }}
									className={`relative group w-full h-full aspect-square overflow-hidden ${i === 2 && "col-span-2 row-span-2"
										}`}
								>
									<div className="group-hover:flex items-center justify-center hidden absolute w-full h-full bg-troo-secondary bg-opacity-75">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
											<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
										</svg>
									</div>
									<img
										src={data?.img}
										alt="galary img"
										className="w-full h-full object-cover object-center"
									/>
								</motion.div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default GalarySection;
