import React from "react";

const BlogCardSmall = ({ date, img, title }) => {
	return (
		<div className="w-full justify-between flex flex-row md:flex-col xl:flex-row items-center gap-4 p-4 bg-troo-secondary rounded-md">
			<div className="aspect-square overflow-hidden rounded-md md:w-full">
				<img src={img} className="w-full h-full object-cover object-center" />
			</div>
			<div className="flex flex-col items-start gap-3 text-troo-light">
				<p className="md:text-base text-sm">22 March, 2023</p>
				<h5 className="line-clamp-2">New Barber House Location In Huston Texas</h5>
			</div>
		</div>
	);
};

export default BlogCardSmall;
