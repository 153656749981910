import React from "react";
import { motion } from "framer-motion";

const TeamCard = ({ data }) => {
	return (
		<div className="relative w-full overflow-hidden rounded-md cursor-pointer group flex items-center justify-center">
			<motion.div initial={{ y: 100 }}
				whileInView={{ y: 0 }}
				transition={{ type: "spring", stiffness: 100 }} className="group-hover:flex flex-col items-center justify-center hidden absolute bottom-2 bg-troo-secondary w-[90%] p-4 rounded-lg gap-2">
				<p className="text-xl font-bold font-secondary text-troo-primary">{data?.title}</p>
				<p className="text-troo-light">{data?.designation}</p>
			</motion.div>
			<img
				src={data?.img}
				alt="team"
				className="w-full h-full object-cover object-center"
			/>
		</div>
	);
};

export default TeamCard;
