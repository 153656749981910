import React from "react";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import AboutSection from "../sections/AboutSection";
import GalarySection from "../sections/GalarySection";
import TeamSection from "../sections/TeamSection";
import ReviewSection from "../sections/ReviewSection";
import DiscountSection from "../sections/DiscountSection";
import AppointSection from "../sections/AppointSection";

const About = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Know About Us" />}>
			<AboutSection isAboutPage />
			<GalarySection />
			<TeamSection />
			<ReviewSection />
			<DiscountSection />
			<AppointSection />
		</Layout>
	);
};

export default About;
