import React from "react";
import Heading from "../components/Heading";
import { BLOGS_DATA } from "../constants";
import BlogCard from "../components/BlogCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogSection = ({ isBlogPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-tertiary text-troo-light">
			<div className="main-container-big gap-8 flex items-center flex-col">
				{!isBlogPage && (
					<Heading
						alignCenter
						heading="NEWS & BLOGS"
						secHeading="Featured tips and tricks exclusively for you!"
						tagline="Read the News & Blogs"
					/>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5">
					{isBlogPage
						? BLOGS_DATA?.map((data, i) => {
							return <BlogCard data={data} />;
						})
						: BLOGS_DATA?.slice(0, 2)?.map((data, i) => {
							return <BlogCard data={data} />;
						})}
				</div>
				{
					!isBlogPage && (
						<div className="w-full flex flex-col items-center">
							<Link to={ROUTES.blogsGrid}>
								<button className="btn-theme-primary self-center mt-10">Read All Articles</button>
							</Link>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default BlogSection;
