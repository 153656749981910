import React from "react";

const CategoryList = ({ listText }) => {
	return (
		<div className="w-full flex items-center justify-between text-sm sm:text-base lg:text-lg text-troo-light font-normal rounded-md">
			<p>{listText}</p>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth={1.5}
				stroke="currentColor"
				className="w-5 h-5"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
				/>
			</svg>
		</div>
	);
};

export default CategoryList;
