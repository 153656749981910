import React from "react";
import Cutter from "../../shared/assets/images/cutter.svg";

const Heading = ({ heading, secHeading, tagline, alignCenter }) => {
	return (
		<div
			className={`flex flex-col gap-4 ${alignCenter ? "items-center text-center" : "items-start"
				}`}
		>
			<h2 className="font-outline-2 text-transparent text-4xl sm:text-5xl lg:text-7xl">{heading}</h2>
			<div className="flex items-center gap-3">
				<img src={Cutter} alt="cutter" />
				<p className="text-sm sm:text-base text-troo-light">{tagline}</p>
			</div>
			<h3 className="text-2xl sm:text-3xl md:text-4xl text-troo-light">{secHeading}</h3>
		</div>
	);
};

export default Heading;
