import React from "react";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import { BlogDetailSection } from "../sections/BlogDetailSection";

const BlogDetails = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Our Blogs & Articles" />}>
			<BlogDetailSection />
		</Layout>
	);
};

export default BlogDetails;
