import React from "react";
import Layout from "../components/Layout";
import BlogSection from "../sections/BlogSection";
import SubPageHeroSection from "../components/SubPageHeroSection";

const Blogs = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Our Blogs & Articles" />}>
			<BlogSection isBlogPage />
		</Layout>
	);
};

export default Blogs;
