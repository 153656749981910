import React from "react";
import Heading from "../components/Heading";
import { REVIEWS_DATA } from "../constants";
import ReviewCard from "../components/ReviewCard";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const ReviewSection = ({ isListingPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-secondary text-troo-light bg-map-img bg-cover bg-center bg-no-repeat">
			<div className="main-container-big gap-8 flex flex-col w-full items-center">
				{
					!isListingPage && (
						<Heading
							alignCenter
							heading="CLIENTS REVIEWS"
							secHeading={
								<>
									Explore our very satisfied <br /> clients testimonials
								</>
							}
							tagline="Meet Our Barber"
						/>
					)
				}
				<div className="w-full grid md:grid-cols-2 xl:grid-cols-3 gap-4">
					{REVIEWS_DATA.map((data) => {
						return <ReviewCard data={data} />;
					})}
				</div>
				{
					!isListingPage && (
						<div className="w-full flex flex-col items-center">
							<Link to={ROUTES.testimonial}>
								<button className="btn-theme-primary self-center mt-10">Read All Reviews</button>
							</Link>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default ReviewSection;
