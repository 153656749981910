import Service1 from "../assets/images/service-1.png";
import Service2 from "../assets/images/service-2.png";
import Service3 from "../assets/images/service-3.png";
import Service4 from "../assets/images/service-4.png";
import Service5 from "../assets/images/service-5.png";
import Service6 from "../assets/images/service-6.png";
import Service7 from "../assets/images/service-7.png";
import Galary1 from "../assets/images/galary/galary-1.png";
import Galary2 from "../assets/images/galary/galary-2.png";
import Galary3 from "../assets/images/galary/galary-3.png";
import Galary4 from "../assets/images/galary/galary-4.png";
import Galary5 from "../assets/images/galary/galary-5.png";
import Galary6 from "../assets/images/galary/galary-6.png";
import Galary7 from "../assets/images/galary/galary-7.png";
import Galary8 from "../assets/images/galary/galary-8.png";
import Galary9 from "../assets/images/galary/galary-9.png";
import Team1 from "../assets/images/team/team-1.png";
import Team2 from "../assets/images/team/team-2.png";
import Team3 from "../assets/images/team/team-3.png";
import Person1 from "../assets/images/reviews/person-1.png";
import Person2 from "../assets/images/reviews/person-2.png";
import Person3 from "../assets/images/reviews/person-3.png";
import Blog1 from "../assets/images/blogs/blog1.png";
import Blog2 from "../assets/images/blogs/blog2.png";
import Blog3 from "../assets/images/blogs/blog3.png";
import Blog4 from "../assets/images/blogs/blog4.png";
import Blog5 from "../assets/images/blogs/blog5.png";
import Blog6 from "../assets/images/blogs/blog6.png";
import Blog7 from "../assets/images/blogs/blog7.png";
import Blog8 from "../assets/images/blogs/blog8.png";
import Blog9 from "../assets/images/blogs/blog9.png";
import Blog10 from "../assets/images/blogs/blog10.png";

export const SERVICE_DATA = [
	{
		img: Service1,
		title: "Beard Trim",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
	},
	{
		img: Service2,
		title: "Haircut Deluxe",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
	},
	{
		img: Service3,
		title: "Hair Cut",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
	},
	{
		img: Service4,
		title: "Hair Wash",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
	},
];

export const PRICING_DATA = [
	{
		img: Service1,
		title: "Hair Cutting Style",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service2,
		title: "Hair Washing",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service3,
		title: "Body Treatments",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service4,
		title: "Beauty & Spa",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service5,
		title: "Stylist Shaving",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service6,
		title: "Multi Hair Colors",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service2,
		title: "Wavy Ivy League Cut",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
	{
		img: Service7,
		title: "High-Top Fade",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority.",
		price: 20,
	},
];

export const GALARY_DATA = [
	{
		img: Galary1,
	},
	{
		img: Galary2,
	},
	{
		img: Galary3,
	},
	{
		img: Galary4,
	},
	{
		img: Galary5,
	},
	{
		img: Galary6,
	},
	{
		img: Galary7,
	},
	{
		img: Galary8,
	},
	{
		img: Galary9,
	},
];

export const TEAM_DATA = [
	{
		img: Team1,
		title: "Erik Genie",
		designation: "Company SEO & Founder",
	},
	{
		img: Team2,
		title: "Erik Genie",
		designation: "Company SEO & Founder",
	},
	{
		img: Team3,
		title: "Erik Genie",
		designation: "Company SEO & Founder",
	},
];

export const REVIEWS_DATA = [
	{
		img: Person1,
		name: "Luck Harper",
		headline: "Loyal Customer",
		review:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy.",
	},
	{
		img: Person2,
		name: "Gullock karman",
		headline: "Loyal Customer",
		review:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy.",
	},
	{
		img: Person3,
		name: "Jonathan Bohnert",
		headline: "Loyal Customer",
		review:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy.",
	},
];

export const BLOGS_DATA = [
	{
		img: Blog1,
		title:
			"The Benefits Of Regular Barber Visits: How To Keep Your Hair Healthy",
		date: "05 APR, 2023",
	},
	{
		img: Blog2,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog3,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog4,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog5,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog6,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog7,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog8,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog9,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
	{
		img: Blog10,
		title: "Fades: Mastering The Most Popular Men’s Hairstyles On 2023",
		date: "05 APR, 2023",
	},
];
