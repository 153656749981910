import React from "react";
import GalarySection from "../sections/GalarySection";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import AppointSection from "../sections/AppointSection";

const Galary = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Photo Gallery" />}>
			<GalarySection isGalaryPage />
			<AppointSection />
		</Layout>
	);
};

export default Galary;
