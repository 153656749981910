import React from "react";
import Heading from "../components/Heading";
import { PRICING_DATA } from "../constants";
import PricingCard from "../components/PricingCard";

const PricingPlanSection = () => {
	return (
		<div className="w-full py-[100px] bg-troo-tertiary bg-opacity-95 text-troo-light">
			<div className="main-container-big gap-14 flex flex-col items-center">
				<div className="flex flex-col items-center w-full gap-4">
					<Heading
						heading="PRICING PLAN"
						secHeading={
							<>
								Unmatched features with <br /> transparent pricing
							</>
						}
						tagline="Awesome Pricing Plan"
						alignCenter
					/>
				</div>
				<div className="w-full grid lg:grid-cols-2 gap-6">
					{PRICING_DATA.map((data, i) => {
						return <PricingCard data={data} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default PricingPlanSection;
