import React from "react";
import Layout from "../components/Layout";
import AboutSection from "../sections/AboutSection";
import ServiceSection from "../sections/ServiceSection";
import PricingPlanSection from "../sections/PricingPlanSection";
import GalarySection from "../sections/GalarySection";
import TeamSection from "../sections/TeamSection";
import ReviewSection from "../sections/ReviewSection";
import DiscountSection from "../sections/DiscountSection";
import AppointSection from "../sections/AppointSection";
import BlogSection from "../sections/BlogSection";
import HeroSection from "../sections/HeroSection";

const Home = () => {
	return (
		<Layout banner={<HeroSection />}>
			<AboutSection />
			<ServiceSection />
			<PricingPlanSection />
			<GalarySection />
			<TeamSection />
			<ReviewSection />
			<DiscountSection />
			<AppointSection />
			<BlogSection />
		</Layout>
	);
};

export default Home;
