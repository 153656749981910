import React from "react";
import Heading from "../components/Heading";
import phone from "../../shared/assets/images/icons/phone-white.svg";
import mail from "../../shared/assets/images/icons/mail-white.svg";

const AppointSection = () => {
	return (
		<div className="w-full py-[100px] bg-troo-tertiary bg-opacity-95 text-troo-light">
			<div className="main-container-big gap-8 items-center flex lg:flex-row flex-col">
				<div className="w-full flex flex-col items-start gap-4">
					<Heading
						heading="APPOINTMENT"
						secHeading={
							<>
								Choose your perfect service &<br /> book an appountment
							</>
						}
						tagline="Make an Appointment"
					/>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since dummy.\Lorem Ipsum is simply dummy text.
					</p>
					<div className="flex xl:flex-row flex-col xl:items-center w-full bg-troo-tertiary rounded-lg">
						<div className="flex items-center gap-4 p-5">
							<img src={phone} alt="phone" />
							<div className="flex flex-col items-start">
								<p>Call Us on</p>
								<h5>+44 123 456 7890</h5>
							</div>
						</div>
						<div className="flex items-center gap-4 p-5">
							<img src={mail} alt="mail" />
							<div className="flex flex-col items-start">
								<p>Email Us on</p>
								<h5>contact@troothemes.com</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="bg-troo-tertiary p-7 rounded-xl md:grid flex flex-col md:grid-cols-2 gap-6 w-full">
					<div className="p-3 md:p-5 text-sm md:text-base rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<input
							type="text"
							placeholder="Full Name"
							className="bg-transparent w-full outline-none"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
							/>
						</svg>
					</div>
					<div className="p-3 md:p-5 text-sm md:text-base rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<input
							type="text"
							placeholder="Phone No"
							className="bg-transparent w-full outline-none"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
							/>
						</svg>
					</div>
					<div className="pr-3 md:pr-5 text-sm md:text-base rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<select className="pl-5 py-5 bg-transparent w-full outline-none">
							<option>Select Servic</option>
							<option>Service 1</option>
							<option>Service 2</option>
							<option>Service 3</option>
						</select>
					</div>
					<div className="pr-3 md:pr-5 text-sm md:text-base rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<select className="pl-5 py-5 bg-transparent w-full outline-none">
							<option>Persons</option>
							<option> 1</option>
							<option> 2</option>
							<option> 3</option>
						</select>
					</div>
					<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<input
							type="date"
							id="date"
							name="date"
							className="bg-transparent w-full outline-none text-troo-light appearance-none"
						/>
						<label htmlFor="date">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
								/>
							</svg>
						</label>
					</div>
					<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2 w-full">
						<input
							type="text"
							placeholder="Time"
							className="bg-transparent w-full outline-none"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</div>
					<button className="w-full mt-4 col-span-2 py-4 px-6 bg-transparent border-2 border-troo-primary rounded-md text-troo-primary">
						Book an Appointment
					</button>
				</div>
			</div>
		</div>
	);
};

export default AppointSection;
