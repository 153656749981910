import React from "react";
import ServiceImg from "../assets/images/hero-background.png";
import ServiceImg2 from "../assets/images/service-1.png";
import ServiceImg3 from "../assets/images/service-2.png";
import ServiceImg4 from "../assets/images/service-3.png";
import ServiceImg5 from "../assets/images/service-4.png";
import ServiceImg6 from "../assets/images/service-5.png";
import ServiceImg7 from "../assets/images/service-6.png";
import ServiceImg8 from "../assets/images/service-7.png";
import CheckList from "../components/CheckList";
import CategoryList from "../components/CategoryList";
import Facebook from "../../shared/assets/images/icons/facebook.svg";
import Twitter from "../../shared/assets/images/icons/x.svg";
import LinkedIn from "../../shared/assets/images/icons/linkedin.svg";
import Instagram from "../../shared/assets/images/icons/instagram.svg";
import BlogCardSmall from "../components/BlogCardSmall";

export const BlogDetailSection = () => {
	return (
		<div className="w-full bg-troo-tertiary">
			<div className="main-container w-full py-[100px]">
				<div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6 items-start">
					<div className="col-span-2 flex flex-col items-start gap-4">
						<div className="w-full aspect-video overflow-hidden rounded-lg">
							<img
								src={ServiceImg}
								alt="service img"
								className="object-cover object-center w-full h-full"
							/>
						</div>
						<h2 className="text-troo-light text-xl sm:text-2xl md:text-3xl lg:text-4xl">
							The Benefits Of Regular Barber Visits: How To Keep Your Hair
							Healthy
						</h2>
						<div className="flex flex-col items-start text-troo-light gap-3">
							<p className="text-troo-light text-sm md:text-base lg:text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore et simply free Lorem ipsum dolor sit amet,
								consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore et simply free text dolore magna aliqua
								lonm andhn.text dolore magna aliqua lonm andhn.notted
								adipisicLorem ipsum dolor sit amet, consectetur notted
								adipisicing elit sed do eiusmod tempor incididunt ut laboreing
								elit sed do eiusmod tempor incididunt.
							</p>
							<p className="text-troo-light text-sm md:text-base lg:text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore simply free Lorem ipsum dolor sit amet,
								consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore.
							</p>
						</div>
						<div className="flex flex-col items-start text-troo-light gap-3">
							<h4>Benefits</h4>
							<p className="text-troo-light text-sm md:text-base lg:text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore simply free Lorem ipsum dolor.
							</p>
						</div>
						<div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4">
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									01
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Cuts And New Style</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									02
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Quality Products</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									03
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Prepared Professionals</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									04
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Prestige & Comfort</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
						</div>
						<p className="font-normal text-troo-light text-sm md:text-base lg:text-lg">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
							tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum
							varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices
							dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut
							sit amet velit cursus quis at nibh.{" "}
						</p>
						<div className="grid grid-cols-1 w-full md:grid-cols-2 gap-6 items-center">
							<div className="flex flex-col items-start gap-5">
								<p className="font-normal text-troo-light text-sm md:text-base lg:text-lg">
									Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
									eiusmod and it ji sit amet, consectetur notted adipisicing
									elit sed do eiusmod tempor.
								</p>
								<CheckList listText="Easy to Use, Built Technology" />
								<CheckList listText="Easy to Built onPowerful Technology" />
								<CheckList listText="Easy to Use, Built onPowerful " />
								<CheckList listText="Easy to Use, Built Technology" />
							</div>
							<div className="aspect-auto overflow-hidden rounded-xl">
								<img
									src={ServiceImg2}
									alt=""
									className="object-cover object-center w-full h-full"
								/>
							</div>
						</div>
						<div className="flex items-center gap-2 text-troo-light">
							<p>Share This Article:</p>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Facebook} alt="facebook" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Twitter} alt="twitter" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={LinkedIn} alt="linked in" className="w-4 h-4" />
							</div>
							<div className="p-2 bg-troo-primary bg-opacity-20 rounded-full">
								<img src={Instagram} alt="instagram" className="w-4 h-4" />
							</div>
						</div>
						<div className="w-full flex flex-col items-start gap-4 text-troo-light">
							<h4>Comments (0)</h4>
							<p>Leave A Comment</p>
							<div className="rounded-xl flex flex-col md:grid grid-cols-2 gap-6 w-full text-troo-light">
								<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2">
									<input
										type="text"
										placeholder="First Name"
										className="bg-transparent w-full outline-none"
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
										/>
									</svg>
								</div>
								<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2">
									<input
										type="text"
										placeholder="Last Name"
										className="bg-transparent w-full outline-none"
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
										/>
									</svg>
								</div>
								<div className="p-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2">
									<input
										type="text"
										placeholder="Email"
										className="bg-transparent w-full outline-none"
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
										/>
									</svg>
								</div>
								<div className="pr-5 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2">
									<select className="pl-5 py-5 bg-transparent w-full outline-none">
										<option>Subject</option>
										<option>Subject 1</option>
										<option>Subject 2</option>
										<option>Subject 3</option>
									</select>
								</div>
								<textarea
									className="p-5 col-span-2 rounded-md bg-transparent border border-troo-light border-opacity-10 outline-none flex items-center justify-between gap-2"
									rows={3}
									placeholder="Message"
								></textarea>

								<button className="w-full mt-4 col-span-2 py-4 px-6 bg-transparent border-2 border-troo-primary rounded-md text-troo-primary">
									Submit Comment
								</button>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 lg:mt-0 w-full md:grid-cols-2 lg:flex lg:flex-col items-start gap-5">
						<div className="w-full h-auto text-troo-tertiary  bg-troo-gray flex flex-col gap-4 items-start rounded-lg p-5 xl:p-8 border border-troo-primary">
							<h6 className="text-2xl text-troo-light">All Services</h6>
							<div className="h-[1px] w-full bg-troo-light bg-opacity-10 flex flex-col items-start gap-4"></div>
							<CategoryList listText="01 Simple Haircut" />
							<CategoryList listText="02 Razor Shaver" />
							<CategoryList listText="03 Japanese Haircut" />
							<CategoryList listText="04 Hair Coloring" />
							<CategoryList listText="05 Beard Trim" />
							<CategoryList listText="06 Face Shave" />
							<CategoryList listText="07 Shaving" />
						</div>
						<div className="w-full h-auto text-troo-tertiary  bg-troo-gray flex flex-col gap-4 items-start rounded-lg p-5 xl:p-8 border border-troo-primary">
							<h6 className="text-2xl text-troo-light">Tags</h6>
							<div className="h-[1px] w-full bg-troo-light bg-opacity-10 flex flex-col items-start gap-4"></div>
							<div className="w-full flex items-center gap-x-3 gap-y-3 flex-wrap">
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Barber
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Shaving
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Haircut
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Hair Coloring
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Face Shave
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Razor Shaver
								</div>
								<div className="py-3 px-4 bg-troo-primary bg-opacity-10 text-troo-light hover:text-troo-primary rounded-md">
									Simple Haircut
								</div>
							</div>
						</div>
						<div className="w-full h-auto text-troo-tertiary  bg-troo-gray flex flex-col gap-4 items-start rounded-lg p-5 xl:p-8 border border-troo-primary">
							<h6 className="text-2xl text-troo-light">Related Blogs</h6>
							<div className="h-[1px] w-full bg-troo-light bg-opacity-10 flex flex-col items-start gap-4"></div>
							<div className="w-full flex flex-col items-start gap-4">
								<BlogCardSmall
									img={ServiceImg3}
									date="22 March, 2023"
									title="New Barber House Location In Huston Texas"
								/>
								<BlogCardSmall
									img={ServiceImg4}
									date="22 March, 2023"
									title="Beard Products, Which One Is The Most Indicated?"
								/>
								<BlogCardSmall
									img={ServiceImg5}
									date="22 March, 2023"
									title="The Benefits Of Regular Barber Visits: How To Keep Your Hair Healthy"
								/>
								<BlogCardSmall
									img={ServiceImg6}
									date="22 March, 2023"
									title="5 Most Iconic Men’s Hairstyles of All Times"
								/>
								<BlogCardSmall
									img={ServiceImg7}
									date="22 March, 2023"
									title="The Best Men’s Hair Design Trend Today"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
