import React from "react";
import Layout from "../components/Layout";
import SubPageHeroSection from "../components/SubPageHeroSection";
import { ServiceDetailSection } from "../sections/ServiceDetailSection";
import AppointSection from "../sections/AppointSection";

const ServiceDetails = () => {
	return (
		<Layout banner={<SubPageHeroSection title="What We Provide" />}>
			<ServiceDetailSection />
			<AppointSection />
		</Layout>
	);
};

export default ServiceDetails;
