import React from "react";
import Heading from "../components/Heading";
import { SERVICE_DATA } from "../constants";
import ServiceCard from "../components/ServiceCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ServiceSection = ({ isListingPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-secondary bg-opacity-95 text-troo-light">
			<div className="main-container-big gap-8 w-full items-center">
				{
					!isListingPage && (
						<div className="flex flex-col md:flex-row md:items-end md:justify-between gap-8 md:gap-14">
							<Heading
								heading="SERVICES"
								secHeading="We always provide sincere service from the heart just for you"
								tagline="What We're Offering"
							/>
							<p className="text-sm md:text-base">
								There are many variations of passages of Lorem Ipsum available, but
								the majority have suffered alteration in some form, by injected
								humour.
							</p>
						</div>
					)
				}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
					{SERVICE_DATA.map((data, i) => {
						return <ServiceCard data={data} />;
					})}
				</div>
				{
					!isListingPage && (
						<div className="w-full flex flex-col items-center">
							<Link to={ROUTES.services}>
								<button className="btn-theme-primary self-center mt-10">Explore all Services</button>
							</Link>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default ServiceSection;
