import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Barber2 from "../../shared/assets/images/barber-2.png";

const SubPageHeroSection = ({ title }) => {
	return (
		<div className="relative py-8 w-full h-[360px] bg-troo-tertiary bg-opacity-90 bg-sub-page-img bg-cover bg-center bg-no-repeat">
			<div className="relative main-container-big flex h-full items-center justify-center">
				<div className="flex w-full flex-col items-center justify-center text-troo-light gap-6">
					<h2>{title}</h2>
					<Breadcrumbs />
				</div>
			</div>
			<img
				src={Barber2}
				alt="barber2"
				className="absolute bottom-0 right-5 w-40 lg:w-72"
			/>
		</div>
	);
};

export default SubPageHeroSection;
