import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./shared/constants/routes";
import Home from "./shared/pages/Home";
import About from "./shared/pages/About";
import Services from "./shared/pages/Services";
import Galary from "./shared/pages/Galary";
import ContactUs from "./shared/pages/ContactUs";
import Blogs from "./shared/pages/Blogs";
import Error from "./shared/pages/Error";
import ServiceDetails from "./shared/pages/ServiceDetails";
import BlogDetails from "./shared/pages/BlogDetails";
import Team from "./shared/pages/Team";
import Testimonial from "./shared/pages/Testimonial";
import("preline");

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.home} element={<Home />} />
				<Route path={ROUTES.aboutUs} element={<About />} />
				<Route path={ROUTES.services} element={<Services />} />
				<Route path={ROUTES.galary} element={<Galary />} />
				<Route path={ROUTES.contactUs} element={<ContactUs />} />
				<Route path={ROUTES.blogsGrid} element={<Blogs />} />
				<Route path={ROUTES.errorPage} element={<Error />} />
				<Route path={ROUTES.serviceDetails} element={<ServiceDetails />} />
				<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
				<Route path={ROUTES.team} element={<Team />} />
				<Route path={ROUTES.testimonial} element={<Testimonial />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
