import React from "react";
import logo from "../../shared/assets/images/Logo.svg";
import ThreeDots from "../../shared/assets/images/three-dots.svg";
import { NAV_ROUTES } from "../constants/navRoutes";
import { Link } from "react-router-dom";
import SecondarySidebar from "./SecondarySidebar";
import PrimarySidebar from "./PrimarySidebar";

const Navbar = () => {
	return (
		<div className="w-full bg-troo-tertiary bg-opacity-95 text-troo-white">
			<div className="main-container flex gap-8 items-center justify-between text-sm md:text-base">
				<Link to="/">
					<img src={logo} alt="brand logo" className="py-6" />
				</Link>
				<div className="hidden lg:flex items-center gap-6 lg:gap-10 text-sm lg:text-lg text-troo-light">
					{NAV_ROUTES.map((route, i) => {
						if (route.isMultiple) {
							return (
								<div class="hs-dropdown relative inline-flex">
									<div className="flex items-center gap-3 hs-dropdown-toggle">
										<p className="text-sm lg:text-base">{route.name}</p>
										<svg class="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
										</svg>
									</div>
									<div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 opacity-0 w-72 hidden z-10 mt-2 min-w-[15rem] shadow-md rounded-lg p-2 " aria-labelledby="hs-dropdown-default">
										<div className="flex flex-col items-start gap-3 w-full bg-troo-primary text-troo-secondary py-4 px-6 rounded-lg">
											{
												route.subPages?.map((nav, i) => {
													return (
														<Link to={nav.path} >{nav.name}</Link>
													)
												})
											}
										</div>
									</div>
								</div>
							);
						} else {
							return <Link className="text-sm lg:text-base" to={route.path}>{route.name}</Link>;
						}
					})}
					<img src={ThreeDots} alt="three dots" className="w-10" data-hs-overlay="#hs-overlay-right" />
					<SecondarySidebar />
					<button className="btn-theme-primary xl:block hidden">
						Book an Appointment
					</button>
				</div>
				<div className="text-troo-light cursor-pointer lg:hidden">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8" data-hs-overlay="#hs-overlay-example">
						<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
					</svg>
				</div>
				<PrimarySidebar />
			</div>
		</div >
	);
};

export default Navbar;
