import React from "react";
import ServiceImg from "../assets/images/hero-background.png";
import ServiceImg2 from "../assets/images/service-1.png";
import ServiceImg3 from "../assets/images/service-2.png";
import ServiceImg4 from "../assets/images/service-3.png";
import ServiceImg5 from "../assets/images/service-4.png";
import ServiceImg6 from "../assets/images/service-5.png";
import ServiceImg7 from "../assets/images/service-6.png";
import ServiceImg8 from "../assets/images/service-7.png";
import CheckList from "../components/CheckList";
import CategoryList from "../components/CategoryList";

export const ServiceDetailSection = () => {
	return (
		<div className="w-full bg-troo-tertiary">
			<div className="main-container w-full py-[100px]">
				<div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6 items-start">
					<div className="col-span-2 flex flex-col items-start gap-4">
						<div className="w-full aspect-video overflow-hidden rounded-lg">
							<img
								src={ServiceImg}
								alt="service img"
								className="object-cover object-center w-full h-full"
							/>
						</div>
						<h2 className="text-troo-light text-4xl">Essentials Haircut</h2>
						<div className="flex flex-col items-start text-troo-light gap-3">
							<h4>Description</h4>
							<p className="text-troo-light text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore et simply free Lorem ipsum dolor sit amet,
								consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore et simply free text dolore magna aliqua
								lonm andhn.text dolore magna aliqua lonm andhn.notted
								adipisicLorem ipsum dolor sit amet, consectetur notted
								adipisicing elit sed do eiusmod tempor incididunt ut laboreing
								elit sed do eiusmod tempor incididunt.
							</p>
							<p className="text-troo-light text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore simply free Lorem ipsum dolor sit amet,
								consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore.
							</p>
						</div>
						<div className="flex flex-col items-start text-troo-light gap-3">
							<h4>What You Get</h4>
							<p className="text-troo-light text-lg font-normal">
								Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
								amet, consectetur notted adipisicing elit sed do eiusmod tempor
								incididunt ut labore simply free Lorem ipsum dolor.
							</p>
						</div>
						<h4 className="text-troo-light text-3xl">
							We create some things your success future
						</h4>
						<div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4">
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									01
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Cuts And New Style</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									02
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Quality Products</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									03
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Prepared Professionals</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
							<div className="bg-troo-secondary flex items-start gap-3 p-6 rounded-lg">
								<div className="aspect-square rounded-full bg-troo-primary bg-opacity-10 p-3 text-troo-primary text-2xl font-extrabold">
									04
								</div>
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-troo-light">Prestige & Comfort</h6>
									<p className="text-troo-light font-light">
										Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum
										dolor sit amet elit.
									</p>
								</div>
							</div>
						</div>
						<p className="font-normal text-troo-light text-lg">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
							tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum
							varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices
							dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut
							sit amet velit cursus quis at nibh.{" "}
						</p>
						<div className="grid grid-cols-1 w-full md:grid-cols-2 gap-6 items-center">
							<div className="flex flex-col items-start gap-5">
								<p className="font-normal text-troo-light text-lg">
									Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
									eiusmod and it ji sit amet, consectetur notted adipisicing
									elit sed do eiusmod tempor.
								</p>
								<CheckList listText="Easy to Use, Built Technology" />
								<CheckList listText="Easy to Built onPowerful Technology" />
								<CheckList listText="Easy to Use, Built onPowerful " />
								<CheckList listText="Easy to Use, Built Technology" />
							</div>
							<div className="aspect-square overflow-hidden rounded-xl">
								<img
									src={ServiceImg2}
									alt=""
									className="object-cover object-center w-full h-full"
								/>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 mt-8 lg:mt-0 w-full md:grid-cols-2 lg:flex lg:flex-col items-start gap-5">
						<div className="w-full h-auto text-troo-tertiary  bg-troo-gray flex flex-col gap-4 items-start rounded-lg p-8 border border-troo-primary">
							<h6 className="text-2xl text-troo-light">All Services</h6>
							<div className="h-[1px] w-full bg-troo-light bg-opacity-10 flex flex-col items-start gap-4"></div>
							<CategoryList listText="01 Simple Haircut" />
							<CategoryList listText="02 Razor Shaver" />
							<CategoryList listText="03 Japanese Haircut" />
							<CategoryList listText="04 Hair Coloring" />
							<CategoryList listText="05 Beard Trim" />
							<CategoryList listText="06 Face Shave" />
							<CategoryList listText="07 Shaving" />
						</div>
						<div className="w-full h-auto text-troo-tertiary  bg-troo-gray flex flex-col gap-4 items-start rounded-lg p-8 border border-troo-primary">
							<h6 className="text-2xl text-troo-light">Photo Gallery</h6>
							<div className="h-[1px] w-full bg-troo-light bg-opacity-10 flex flex-col items-start gap-4"></div>
							<div className="w-full grid grid-cols-2 gap-4">
								<img
									src={ServiceImg3}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
								<img
									src={ServiceImg4}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
								<img
									src={ServiceImg5}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
								<img
									src={ServiceImg6}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
								<img
									src={ServiceImg7}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
								<img
									src={ServiceImg8}
									className="w-full h-full aspect-square rounded-lg object-cover object-center"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
