import React from "react";

const Error = () => {
	return (
		<div className="min-h-screen py-[100px] bg-troo-tertiary px-10 md:px-20 bg-errorPage bg-no-repeat bg-center flex flex-col items-center text-center justify-center gap-8 text-troo-light">
			<h1 className="text-9xl">404</h1>
			<div className="flex flex-col items-center gap-4">
				<h2>Sorry! page did not found</h2>
				<p className="text-xs md:text-lg">
					The page you look for does not exist or another error occured <br />{" "}
					please kindly refreash or retun to home page.
				</p>
				<button className="py-4 px-6 bg-transparent border-2 border-troo-primary rounded-md text-troo-primary">
					Back to homepage
				</button>
			</div>
		</div>
	);
};

export default Error;
