import React from "react";
import StarImg from "../../shared/assets/images/icons/star.svg";
import QuoteFilled from "../../shared/assets/images/icons/quote-filled.svg";

const ReviewCard = ({ data }) => {
	return (
		<div className="relative w-full p-6 md:p-8 flex flex-col items-start gap-6 bg-troo-tertiary text-troo-light pt-16 mt-12">
			<div className="absolute -top-10">
				<img
					src={data?.img}
					alt="person"
					className="aspect-square border-4 border-troo-primary border-opacity-10 rounded-full"
				/>
				<div className="absolute bottom-0 right-0 p-2 rounded-full bg-troo-primary">
					<img src={QuoteFilled} alt="quote filled" />
				</div>
			</div>
			<p className="text-base">{data?.review}</p>
			<div className="w-full h-[1px] bg-troo-primary bg-opacity-10" />
			<div className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
				<div className="flex flex-col items-start w-full">
					<h4 className="text-troo-primary">{data?.name}</h4>
					<p className="text-troo-light text-sm">{data?.headline}</p>
				</div>
				<div className="flex items-center w-full sm:justify-end">
					<div className="p-2 sm:p-4 bg-troo-primary bg-opacity-10 flex items-center gap-1 self-end justify-self-end">
						<img src={StarImg} alt="star" />
						<img src={StarImg} alt="star" />
						<img src={StarImg} alt="star" />
						<img src={StarImg} alt="star" />
						<img src={StarImg} alt="star" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewCard;
