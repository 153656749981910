import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Services",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.services,
				name: "Services",
			},
			{
				path: ROUTES.serviceDetails,
				name: "Service Details",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.team,
				name: "Team",
			},
			{
				path: ROUTES.testimonial,
				name: "Testimonials",
			},
			{
				path: ROUTES.galary,
				name: "Galary",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogsGrid,
				name: "Blogs",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactUs,
		name: "Contact Us",
	},
];
