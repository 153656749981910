export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	services: "/services",
	serviceDetails: "/services/service-details",
	galary: "/galary",
	portfolioDetails: "/portfolio/portfolio-details",
	team: "/team",
	testimonial: "/testimonial",
	teamDetails: "/team/team-details",
	blogsGrid: "/blogs",
	blogsList: "/blogs-list",
	blogDetails: "/blogs/blog-details",
	errorPage: "/404",
	comingSoon: "/coming-soon",
	contactUs: "/contact-us",
};
