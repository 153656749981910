import React from 'react'
import Layout from '../components/Layout'
import SubPageHeroSection from '../components/SubPageHeroSection'
import AppointSection from '../sections/AppointSection'
import TeamSection from '../sections/TeamSection'

const Team = () => {
  return (
    <Layout banner={<SubPageHeroSection title="Meet Our Team" />}>
      <TeamSection isListingPage />
      <AppointSection />
    </Layout>
  )
}

export default Team