import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ children, banner }) => {
	return (
		<>
			<div className="w-full bg-home-hero-banner bg-center bg-cover bg-no-repeat">
				<Navbar />
				{banner}
			</div>
			{children}
			<Footer />
		</>
	);
};

export default Layout;
