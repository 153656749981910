import React from 'react'
import Layout from '../components/Layout'
import SubPageHeroSection from '../components/SubPageHeroSection'
import ReviewSection from '../sections/ReviewSection'
import AppointSection from '../sections/AppointSection'

const Testimonial = () => {
  return (
    <Layout banner={<SubPageHeroSection title="What People Say" />}>
      <ReviewSection isListingPage />
      <AppointSection />
    </Layout>
  )
}

export default Testimonial