import React from "react";
import Heading from "../components/Heading";
import { TEAM_DATA } from "../constants";
import TeamCard from "../components/TeamCard";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const TeamSection = ({ isListingPage }) => {
	return (
		<div className="w-full py-[100px] bg-troo-tertiary bg-opacity-95 text-troo-light">
			<div className="main-container-big gap-8 flex flex-col w-full items-center">
				{
					!isListingPage && (
						<Heading
							alignCenter
							heading="OUR TEAM"
							secHeading="Our successfull hair stylists"
							tagline="Meet Our Barber"
						/>
					)
				}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					{TEAM_DATA?.map((data, i) => {
						return <TeamCard data={data} />;
					})}
				</div>
				{
					!isListingPage && (
						<div className="w-full flex flex-col items-center">
							<Link to={ROUTES.team}>
								<button className="btn-theme-primary self-center mt-10">Views All Members</button>
							</Link>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default TeamSection;
