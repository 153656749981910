import React from "react";
import { motion } from "framer-motion";

const PricingCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }} className="w-full p-5 md:p-8 rounded-md bg-troo-secondary flex items-center gap-4">
			<div className="aspect-square rounded-full border-3 border-troo-primary overflow-hidden">
				<img
					src={data?.img}
					alt={data.title}
					className="w-full h-full object-center object-cover"
				/>
			</div>
			<div className="flex flex-col items-start gap-2">
				<h4 className="text-troo-light">{data?.title}</h4>
				<p className="text-troo-light text-sm md:text-base">{data?.desc}</p>
			</div>
			<div className="p-4 aspect-square bg-troo-primary bg-opacity-10 rounded-full flex items-center justify-center text-center">
				${data?.price}
			</div>
		</motion.div>
	);
};

export default PricingCard;
